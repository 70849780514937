const Biblios = [
    {
        title: "Je me reconstruis après un burn-out",
        src: "https://livre.fnac.com/a11254919/Carol-Dequick-Je-me-reconstruis-apres-un-burn-out?oref=00000000-0000-0000-0000-000000000000&amp;Origin=SEA_GOOGLE_PLA_BOOKS"
    }, {
        title: "Renaissance - il y a une vie après le burn-out",
        src: "https://file-attente.cultura.com/?c=cultura&e=culturaprod&ver=v3-javascript-3.6.3&cver=57&man=Production&cid=fr-FR&l=Cultura.com&t=https%3A%2F%2Fwww.cultura.com%2Frenaissance-9782840019602.html%3Flgw_code%3D23392-9782840019602&kupver=cloudflare-1.2.0"
    }, {
        title: "Guide du Burn-Out - Comment l'éviter, Comment en Sortir",
        src: "https://livre.fnac.com/a10311630/Anne-Everard-Guide-du-burn-out?oref=a5768baf-cae6-dacd-d677-92063633415a&amp;Origin=CMP_GOOGLE_MP_LIV"
    }, {
        title: "Burn-out - Le vrai du faux !",
        src: "https://livre.fnac.com/a9557896/Ali-Afdjei-Burn-out-Le-vrai-du-faux?oref=00000000-0000-0000-0000-000000000000&amp;Origin=SEA_GOOGLE_PLA_BOOKS"
    },
];

export default Biblios;