import React from 'react';

const Activities = () => {
    return (
        <div id="activities" className="section">
            <h2>Les Activités</h2>
            <div className="content">
                <p className="reveal-right">
                    La Bulle de Vert n’est pas un établissement de santé ni une structure médicalisée. Ici pas de
                    soignants ni de blouses blanches. De part leurs formations et expériences professionnelles
                    respectives, Karine et Stéphane ont à cœur d’accompagner leurs 3 résidents, en groupe ou
                    individuellement, d’être à leur écoute, de respecter le temps de chacun. Vous serez accueillis en
                    toute amitié et simplicité, sans aucun jugement ni exigence, mais avec bienveillance, écoute et
                    compréhension.
                    <br></br><br></br>
                    Afin de renforcer le dispositif mis en place, La Bulle de Vert est en lien étroit avec différents
                    partenaires professionnels (médecin généraliste, art thérapeute, sophrologue …) qui, sans
                    remplacer les traitements et suivis en cours, peuvent intervenir ponctuellement. Une psychologue
                    spécialisée dans la souffrance au travail organise notamment un groupe de parole sur place une
                    fois par cession. Elle peut également intervenir individuellement à la demande des résidents qui
                    en éprouvent le besoin.
                    <br></br><br></br>
                    Vous pourrez partager avec Karine et Stéphane dans un cadre familial des activités simples,
                    douces et rassénérantes : petits travaux d’entretien sur la prairie ou en forêt, soins des animaux,
                    travail au potager en permaculture, séances de méditation de pleine conscience, balades et
                    randonnées dans la région, sortie en bord de mer … Chaque résident est libre de s&#39;engager dans
                    les activités de son choix. Tout est mis en œuvre pour vous permettre de vous poser, de prendre
                    du recul et, à travers ces activités facteur de calme et de sérénité intérieurs, de vous engager à
                    votre rythme sur le chemin d’une reconstruction personnelle.
                    <br></br><br></br>
                    Pendant tout votre séjour, nous profiterons de petits plaisirs simples : écouter le chant des
                    oiseaux, regarder le coucher de soleil, caresser un animal, rire ou pleurer autour d’un feu de camp,
                    confectionner et partager ensemble un repas …
                </p>
            </div>
            <div className="content">
                <p className="reveal">
                    L’ancien moulin et son pressoir sont situés
                    sur un grand terrain de près de 7 hectares, un
                    vrai poumon vert constitué de prés, de
                    prairies et de bois. Ils disposent en particulier
                    d’un large potager en permaculture.
                    <br></br><br></br>
                    Semer, planter, tailler, tondre entretenir et
                    récolter, c’est l’occasion privilégiée de s’aérer
                    la tête tout en se reconnectant à la nature.
                    <br></br><br></br>
                    C’est s’autoriser en douceur à récupérer de
                    façon active en prenant en compte la notion
                    de temps nécessaire aux cultures comme à sa
                    reconstruction personnelle.
                </p>
                <img id="one" src="/images/undraw_coolness_dtmq.svg" alt=""></img>
            </div>
            <div className="content">
                <img id="two" src="/images/undraw_refreshing_beverage_td3r.svg" alt=""></img>
                <p className="reveal-right">
                    Il ne saurait y avoir de dépaysement et de retour
                    à la nature sans les animaux. Dans son écrin de
                    verdure, La Bulle de Vert ce sont aussi des
                    chiens, des chats, des poules, des moutons, des
                    chevaux, et quand la chance est au rendez-vous
                    des renards, des écureuils, des chevreuils…
                    <br></br><br></br>
                    Les observer, les nourrir, les soigner, gagner
                    progressivement leur affection, c’est là encore
                    s’engager tout en douceur dans une activité de
                    ressourcement.
                </p>
            </div>
            <div className="content">
                <p className="reveal">
                    Méditer en pleine conscience c’est vivre le
                    moment présent et en prendre conscience.
                    <br></br><br></br>
                    C’est simplement se rendre disponible et
                    ouvert en accueillant le moment présent, sans
                    jugement, sans l’objectif de « bien faire », sans
                    résultat à atteindre. Cette pratique aujourd’hui
                    reconnue par la science favorise la sérénité,
                    elle permet de lutter contre le stress, de
                    réduire l’anxiété et de retrouver un bien-être
                    physique et mental.
                    <br></br><br></br>
                    Nous ne sommes pas des grands maîtres, ni des professeurs, mais nous pratiquons
                    régulièrement la méditation de pleine conscience et pourrons vous initier ou méditer avec vous
                    en toute simplicité.
                </p>
                <img id="three" src="/images/undraw_loading_re_5axr.svg" alt=""></img>
            </div>
            <div className="content">
                <img id="four" src="/images/undraw_walking_outside_re_56xo.svg" alt=""></img>
                <p className="reveal-right">
                    La marche à pied est une activité idéale
                    autour de La Bulle de Vert.
                    <br></br><br></br>
                    La Suisse normande est réputée pour ses sentiers
                    de randonnées, son environnement
                    verdoyant et son patrimoine naturel.
                    Pratiquée de façon régulière, la marche à
                    pied permet une dépense physique
                    progressive et sans excès, elle favorise
                    l’appétit comme le sommeil.
                    <br></br><br></br>
                    Marcher 30 à 45 minutes c’est bon pour le corps
                    comme pour l’esprit car c’est également
                    l’occasion d’observer la faune et la flore,
                    d’échanger tout en respectant le besoin
                    d’introspection de chacun.
                </p>
            </div>
        </div>
    );
};

export default Activities;