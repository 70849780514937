const Articles = [
    {
        title: 'Repérage et prise en charge',
        src: "https://www.has-sante.fr/jcms/c_2769318/fr/reperage-et-prise-en-charge-cliniques-du-syndrome-d-epuisement-professionnel-ou-burnout"
    },
    {
        title: "Epuisement professionnel ou burnout",
        src: "https://www.inrs.fr/risques/epuisement-burnout/ce-qu-il-faut-retenir.html"
    },
    {
        title: "Le travail c’est plus la santé",
        src: "https://www.franceculture.fr/emissions/la-methode-scientifique/la-methode-scientifique-emission-du-lundi-09-mars-2020"
    }, {
        title: "Le Burnout",
        src: "https://www.academie-medecine.fr/wp-content/uploads/2016/02/26-fev-2016-RAPPORT-ACADEMIE-Burn-out-V3.pdf"
    }, {
        title: "Le burn out chez les indépendants",
        src: "https://www.itg.fr/portage-salarial/les-actualites/burn-out-independants-eviter-reconnaitre-premiers-signes"
    }, {
        title: "Le burn-out des professions juridiques",
        src: "https://www.village-justice.com/articles/burn-out-des-professions-juridiques,36807.html"
    }, {
        title: "Paul, reconverti après un burn-out",
        src: "https://medium.com/oser-r%C3%AAver-sa-carri%C3%A8re/paul-ex-avocat-reconverti-apr%C3%A8s-un-burn-out-d491b1f39387"
    }, {
        title: "Epuisement et burn-out",
        src: "https://www.amnesty.fr/actualites/covid-19-sante-mentale-des-soignants-cauchemars-epuisement-burnout"
    },
];

export default Articles;