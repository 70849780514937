import React from 'react';
import { NavLink } from 'react-router-dom';

const Sejours = () => {
    return (
        <div id="sejours" className="section">
            <h2>Les Sejours</h2>
            <div className="content">
                <p className="reveal-right">
                    La Bulle de Vert propose des séjours d’une ou deux cessions de 10 jours chacune. Un séjour de
                    10 jours peut parfois suffire pour faire une pause et se remobiliser. L’expérience montre toutefois
                    que pour sortir du burn-out il faut avant tout lâcher prise, s’accorder de vivre sans objectif, sans
                    résultat à atteindre. Le facteur temps est essentiel.
                    Nous pouvons vous accueillir dès lors que vous êtes en situation de burn-out stabilisé et libre de
                    toute dépendance (drogue, alcool, …).
                    <br></br><br></br>
                    L’avis de votre médecin ou psychologue pour participer à ce genre de séjour est important. Un
                    certificat d’aptitude attestant votre capacité à vivre un séjour résidentiel dans un lieu de type
                    communauté non médicalisée vous sera demandé.
                    <br></br><br></br>
                    <br></br><br></br>
                    <NavLink to="/reserver" className="cta">Réserver un séjour</NavLink>
                    <br></br><br></br>
                    <br></br><br></br>
                    La participation aux frais est dégressive et dépend de la durée de votre séjour. Elle varie en
                    fonction de celle-ci, de 60 à 70 euros environ par jour, soit moins que le prix d’une nuit dans un
                    hôtel de classe moyenne, et sans commune mesure avec le prix d’un séjour en clinique. Cette
                    participation forfaitaire sert à couvrir les frais d’hébergement, de fournitures (eau, électricité,
                    chauffage, internet …) et d’équipements. Elle permet également d’assurer l’entretien des lieux et
                    de maintenir les bonnes conditions d’accueil de nos résidents, dans un lieu chaleureux et
                    confortable.
                    <br></br><br></br>
                    Quelle que soit la période de l’année, peu importe la saison, un séjour de 10 jours à La Bulle de
                    Vert revient ainsi à 700 euros, et un séjour de 20 jours à 1 200 euros.
                    Vous pourrez également être rejoint ponctuellement lors de votre séjour par la personne de votre
                    choix, sans coût supplémentaire.
                    <br></br><br></br>
                    Renseignez-vous auprès de vos mutuelles, assurances et employeurs qui peuvent le cas échéant
                    prendre en charge, même partiellement, les séjours à La Bulle de Vert.
                    L’accompagnement de Karine et Stéphane est lui entièrement bénévole, les activités avec eux
                    sont totalement désintéressées et gratuites.
                    <br></br><br></br>
                    <br></br><br></br>
                    <NavLink to="/contact" className="cta">Plus de renseigments</NavLink>
                    <br></br><br></br>
                </p>
            </div>
        </div>
    );
};

export default Sejours;