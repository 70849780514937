import React from 'react';

const PresentationText = () => {
    return (
        <div id="text">
            <img src="/images/undraw_friends_r511.svg" alt="friends"></img>
            <div>
                <h1>Présentation</h1>
                <p className="reveal-right">
                    La Bulle de Vert est née de l’expérience personnelle de Karine et Stéphane. Tous deux ont
                    connu en 2019 et 2020 un burn-out sévère. Karine était alors Infirmière en psychiatrie à l’Hôpital
                    et son compagnon Avocat. Ils ont réalisé ensemble l’importance de ne pas se sentir isolé, et
                    compris que l’écoute et la compréhension avaient été essentielles pour leur permettre,
                    individuellement et en tant que couple, de surmonter cette épreuve difficile et de se reconstruire
                    ensemble.
                </p>
                <div id="buttons">
                    <button id="kp">Témoignage de Karine</button>
                    <button id="sc">Témoignage de Stéphane</button>
                </div>
            </div>
        </div>
    );
};

export default PresentationText;