import { Route, Switch } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Nav from './components/Nav';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Book from './pages/Book';
import Gallery from './pages/Gallery';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import Presentation from './pages/Presentation';
import Sejourner from './pages/Sejourner';
import Comprendre from './pages/Comprendre';
import Further from './pages/Further';
import Mentions from './pages/Mentions';

function App() {
  const lang = "fr_FR";
  return (
    <>
      <Nav />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/la-bulle/gallerie" component={Gallery} />
        <Route exact path="/la-bulle" component={Presentation} />
        <Route exact path="/la-bulle/sejourner" component={Sejourner} />
        <Route exact path="/le-burnout" component={Comprendre} />
        <Route exact path="/le-burnout/aller-plus-loin" component={Further} />
        <Route exact path="/reserver" component={Book} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/mentions-legales" component={Mentions} />
      </Switch>
      <Footer />
      <MessengerCustomerChat
        pageId="102048852027889"
        appId="602703010964815"
        language={lang}
      />
    </>
  );
}

export default App;
