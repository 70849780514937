const Videos = [
    {
        title: "La mécanique burn out",
        src: "https://www.youtube.com/watch?v=VWC6Y1gimDI"
    },
    {
        title: "Quand le travail rend malade",
        src: "https://www.youtube.com/watch?v=C2hlCQOhtS0"
    }, {
        title: "Dans le secret du burn out",
        src: "https://www.youtube.com/watch?v=VL2SNo1ZplY"
    }, {
        title: "Le burn out, comment s'en sortir ?",
        src: "https://www.youtube.com/watch?v=284mL4UUmrw"
    }, {
        title: "Burn-out : quand travailler nous rend malade",
        src: "https://www.youtube.com/watch?v=bIBguQzHprE"
    }, {
        title: "Se guérir du burn-out et la dépression",
        src: "https://www.youtube.com/watch?v=EFPzJ0uVclg"
    }, {
        title: "Infirmières, médecins : dépression et burnout",
        src: "https://www.youtube.com/watch?v=rTHoqr8H9ug"
    }, {
        title: "Débat burnout",
        src: "https://www.youtube.com/watch?v=ddl5A6BcFk8"
    },
];

export default Videos;