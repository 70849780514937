import React from 'react';
import CloseNav from '../functions/CloseNav';

const Gallery = () => {
    CloseNav();
    var pics = [];
    React.useEffect(() => {
        const popup = document.getElementById('popup');
        const container = document.getElementById('popup-container');
        const imgs = Array.from(document.querySelectorAll('.picture'));

        imgs.forEach(img => {
            img.addEventListener('click', () => {
                container.style.display = "flex";
                popup.style.background = img.dataset.link;
                popup.style.backgroundSize = "cover";
                popup.style.backgroundPosition = "center center";
            })
        })

        container.addEventListener('click', () => {
            container.style.display = "none";
        })
    })

    for (let i = 1; i < 11; i++) {
        pics.push(
            <div className="picture" style={{
                background: "url('/images/gallery/pic" + i + ".jpg')",
                backgroundSize: "cover",
                backgroundPosition: "center center"
            }}
                data-link={"url('/images/gallery/pic" + i + ".jpg')"}
            >

            </div>
        )
    }
    return (
        <div id="gallery">
            {pics}
            <div id="popup-container">
                <div id="popup"></div>

            </div>
        </div>
    );
};

export default Gallery;