import React from 'react';

const Place = () => {
    return (
        <div id="lieu" className="section">
            <h2>Le Lieu</h2>
            <div className="content">
                <div className="img"></div>
                <p className="reveal-right">
                    La Bulle de Vert est située en Normandie, au cœur de la Suisse Normande, à 15 minutes du
                    centre de Flers et moins de 3 heures de train de Paris. Les plages sont à une heure de voiture.
                    <br></br><br></br>
                    Vous pouvez naturellement venir par la route avec votre véhicule, mais ce n’est en rien une
                    obligation. Nous pouvons venir vous chercher à la gare de Flers, et vous trouverez sur place tout
                    ce qui est nécessaire à votre séjour. Le cas échéant, nous pourrons vous accompagner à
                    l’extérieur, notamment pour faire vos courses (à proximité marchés, producteurs locaux dont
                    certains en bio, artisans, hypermarché …).
                    <br></br><br></br>
                    Cet ancien moulin bordé par deux cours d’eau et lové dans un écrin de verdure de près de 7
                    hectares, est propice au dépaysement, au calme et à la sérénité, trois piliers indispensables à la
                    prise de recul.
                    <br></br><br></br>
                    Dans cet environnement naturel et préservé, vous pourrez, en même temps que d’autres et dans
                    un cadre non médicalisé, prendre du temps pour vous, lâcher prise et vivre pour quelques temps
                    au jour le jour, à l’abri de la pression, du regard extérieur, de l’incompréhension et des
                    maladresses des autres.
                    <br></br><br></br>
                    La Bulle de Vert accueille ses résidents dans un ancien pressoir totalement indépendant,
                    composé de 3 chambres individuelles confortables avec lit double, rangements, salle de douche et
                    toilettes privatives, d’une grande pièce de vie commune avec salon, poêle à bois, cuisine
                    aménagée et équipée, et d’une terrasse extérieure donnant sur la nature.
                    <br></br><br></br>
                    Cette configuration vous permet de conjuguer, à votre rythme et selon vos envies, temps de
                    solitude nécessaire au repos et à l’introspection, et ainsi favoriser le travail sur soi, et temps de vie
                    partagés en groupe, (préparer les repas, discuter, choisir et regarder un film ensemble, écouter de
                    la musique …) afin d’éviter l’isolement et ainsi redonner de l’élan puis de l’envie.
                    <br></br><br></br>
                    Votre séjour à La Bulle de Vert pourra aussi être l’occasion de faire le point sur votre situation
                    professionnelle, administrative et juridique.
                </p>
            </div>
        </div>
    );
};

export default Place;