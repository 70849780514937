import React from 'react';
import Form from '../components/Form';
import CloseNav from '../functions/CloseNav';

const Contact = () => {
    CloseNav();
    setTimeout(() => {
        const ratio = .25;
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: ratio
        }

        const callback = function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.intersectionRatio > ratio) {
                    observer.unobserve(entry.target);
                    entry.target.classList.add('reveal-visible');
                } else {
                }
            })
        }
        const observer = new IntersectionObserver(callback, options);
        document.querySelectorAll('.reveal-right').forEach(function (r) {
            observer.observe(r);
        });

    }, 500);
    return (
        <div id="contact">
            <img src="/images/undraw_cabin_hkfr.svg" alt="contact-svg" />
            <Form />
        </div>
    );
};

export default Contact;