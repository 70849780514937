import React from 'react';
import PresentationText from '../components/PresentationText';
import Testimonial from '../components/Testimonial';
import CloseNav from '../functions/CloseNav';

const Presentation = () => {
    CloseNav();
    var [render, setRender] = React.useState(<PresentationText />);
    React.useEffect(() => {
        const sc = document.getElementById('sc');
        const kp = document.getElementById('kp');
        const back = document.getElementById('back');

        if (sc) {
            sc.addEventListener('click', () => {
                setRender(<Testimonial
                    name={"Stephane"}
                    paragraphs={[
                        "J’ai consacré toute ma carrière professionnelle à l’activité judiciaire, d’abord comme Avoué à la Cour puis comme Avocat, spécialisé notamment dans l’aide aux victimes.Je crois que dès 2015 ou 2016 ma relation à l’activité d’Avocat s’est abîmée, fissurée.Peu à peu se sont installés un stress chronique, une tension permanente, une perte de repères, une absence de sens dans mon travail.J’exprimais un ras le bol général sans comprendre que le mal - être qui s’installait et grandissait en moi était beaucoup plus grave.J’ai commencé à douter de moi, de mes compétences, à me faire une montagne de chaque tâche, de chaque dossier, de chaque jour au cabinet, à avoir littéralement peur d’une simple audience après plus de 20 ans de métier…",
                        "Sont venus les ruminations, les troubles du sommeil, difficultés d’endormissement et réveils nocturnes, les maux de tête et d’estomac quotidiens, l’anxiété généralisée et les pleurs, le dimanches soir, les retours devacances, les veilles d’audience … Continuellement stressé, irritable, objet d’une fatigue intense, tous me sont devenus insupportables : clients, confrères, magistrats … La réception d’un simple email pouvait suffire à me plonger dans une angoisse indescriptible. J’arrivais généralement à mon cabinet vers 7h 30 le matin pour en repartir vers 18 heures, et je passais chacune de ces longues journées en apnée … En 2019 j’ai pris la décision de travailler le vendredi à la maison. C’était reculer pour mieux sauter. Grâce à cette bouffée d’air j’ai tenu un an de plus … avant de craquer.",
                        "Début 2020, la pandémie de COVID 19 nous contraint à fermer le cabinet et à mettre tout le monde en télétravail.Je reçois l’obligation de me confiner comme une opportunité, une occasion de souffler, de prendre du recul et de me « réparer ».Nouvelle erreur car si les audiences sont suspendues et renvoyées, je reste connecté en permanence, au cabinet, aux emails, aux juridictions ...Mon mal - être n’a pas disparu, il s’est amplifié et déplacé, il est maintenant chez moi, à la maison, 24 heures sur 24. Je n’ai plus d’endroit où me réfugier, et je ne parviens plus du tout à relâcher la pression.Vient alors l’heure du déconfinement. Ce sera pour moi le détonateur : je n’ai pas tenu plus de 2 semaines avant cette fois - ci de m’effondrer complètement.",
                        "Il aura fallu 7 mois d’arrêt de travail, de suivi médical, de soutien psychologique et de traitements médicamenteux, pour seulement commencer à aller mieux.Et X mois supplémentaires pour me reconstruire vraiment.J’ai eu la chance d’être accompagné par un médecin généraliste bienveillant et compréhensif, et une psychologue spécialisée dans la souffrance au travail tout aussi géniale.Je ne les remercierai jamais assez.Mais j’ai aussi eu la chance d’avoir à mes côtés ma compagne qui, elle aussi vivait un burn - out.Vivre tous les deux et en même temps cette épreuve l’a évidemment rendu plus rude et plus difficile encore, cela a multiplié les embuches et nous a parfois tiré vers le bas.Mais cela nous a aussi et surtout tiré vers le haut car chacun comprenait ce que l’autre pouvait vivre et ressentir.Au final chacun a été une bouée pour l’autre, attentif, bienveillant, compréhensif, patient … Ensemble nous avons évité l’isolement qui guette si souvent la personne en situation de burn - out, même lorsqu’elle est entourée et aimée.",
                        "C’est cette expérience de vie personnelle et de couple qui a inspiré notre projet de la Bulle de Vert."
                    ]}
                    picSrc={"/images/sc.JPG"}
                    other={"Karine"}

                />)
            })
        }

        if (kp) {
            kp.addEventListener('click', () => {
                setRender(<Testimonial
                    name={"Karine"}
                    paragraphs={[
                        "J’ai été infirmière en psychiatrie dans un hôpital pendant 10 ans, de 2011 à 2021, d’abord dans un service en intra hospitalier(toutes pathologies psychiatriques confondues pour des hospitalisations de 3 semaines en moyenne mais parfois bien davantage), puis en  unité de crise et de négociations de soins avec des missions très variées(accompagnement des 6 patients dans le service pour des hospitalisations courtes de 3 jours en moyenne et réorientation, consultations d’accueil aux urgences, visites à domicile, permanence téléphonique 24h sur 24, entretiens infirmiers de suivi pendant environ un mois …). C’est un métier que j’avais choisi, voulu et que je croyais être le dernier de ma carrière professionnelle. Profession pour ma part surinvestie qui m’a enrichie, passionnée puis fatiguée, épuisée allant même jusqu’à la détester.",
                        "J’ai exercé pendant dix ans de jour comme de nuit, en 12h mais aussi en 7h30, dans une société où la maladie mentale fait encore débat et dans un système où l’humain a de moins en moins de valeur.Au - delà de la fatigue physique avec des troubles récurrents du sommeil(inversion du cycle jour / nuit), une prise de poids de 10 kilos, des douleurs diverses, un accident ischémique transitoire(AIT).",
                        "Je ne trouvais plus de SENS à ce que je faisais, j’étais en désaccord avec le système dans lequel j’exerçais et ça me hantait : angoisses, tristesse, colère, ruminations, insomnies étaient devenues quotidiennes.Mais coûte que coûte ne m’autorisant jamais à lâcher, je devais continuer jusqu’à ce qu’un évènement dans ma sphère privée me fasse vaciller, en l’occurrence le décès d’un oncle représentant une figure paternelle.",
                        "Après des alertes répétées de mon corps, je ne pouvais plus physiquement y aller. De plus complètement débordée émotionnellement, je n’étais plus en capacité d’accompagner.A ce moment - là je croyais que tout s’écroulait et la seule chose à laquelle je pensais était « quel constat d’échec!!! » la culpabilité faisant partie intégrante de tous ces mécanismes.",
                        "J’ai été arrêtée cinq semaines pendant lesquelles l’épuisement et le tumulte émotionnel ont été grandissants puis j’ai repris pendant un an en mi - temps thérapeutique, accompagnée par un médecin généraliste et une psychologue.Avec le recul, je pense qu’un arrêt complet plus long aurait été nécessaire, ce qui à l’époque n’était pas entendable pour moi.",
                        "C’était le début du chemin pour comprendre, accepter, reconnaître mes émotions jusque-là refoulées, me sentir légitime, rebondir vers une belle reconstruction et retrouver les valeurs qui m’animaient tant, ainsi que la place que je veux avoir dans ma vie.",
                        "Aujourd’hui je ne regrette rien et je me dis que cet évènement difficile était en réalité une chance qui m’a aidée à reconsidérer ma vie et faire des choix heureux."
                    ]}
                    picSrc={"/images/KP.JPG"}
                    other={"Stephane"}
                />)
            })
        }

        if (back) {
            back.addEventListener('click', () => {
                setRender(<PresentationText />)
            })
        }


    })
    setTimeout(() => {
        const ratio = .25;
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: ratio
        }

        const callback = function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.intersectionRatio > ratio) {
                    observer.unobserve(entry.target);
                    entry.target.classList.add('reveal-visible');
                } else {
                }
            })
        }
        const observer = new IntersectionObserver(callback, options);
        document.querySelectorAll('.reveal-right').forEach(function (r) {
            observer.observe(r);
        });

    }, 500);
    return (
        <div id="presentation">
            {render}
        </div>
    );
};

export default Presentation;