import React from 'react';
import { MdMenuBook } from 'react-icons/md';

const Biblio = ({ src, titre }) => {
    return (
        <div className="biblio" data-link={src}>
            <MdMenuBook />
            <h3>{titre}</h3>
        </div>
    );
};

export default Biblio;