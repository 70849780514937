import React from 'react';
import CloseNav from '../functions/CloseNav';

const Mentions = () => {
    CloseNav();

    return (
        <div id="mentions">
            <h1>Mentions Légales</h1>
            <hr />
            <p>Éditeur : <a href="https://sonofcoffee.fr" rel="noreferrer" target="_blank">Son of Coffee Digital</a> </p>
            <p>Directeur de la publication : <a href="https://sonofcoffee.fr" rel="noreferrer" target="_blank">Son of Coffee Digital</a> </p>
            <p>Hébergeur : Gandi.net</p>
            <p>Propriétaire : SAS La Bulle de Vert</p>
            <hr />
            <h5>Mentions légales</h5>
            <p>Le présent document constitue les conditions générales d’utilisation du site accessible sous l’adresse « https://labulledevert.fr » (ci-après dénommé le « Site »). En accédant ou en utilisant ce Site, vous êtes réputé accepter ces conditions générales et nous nous réservons le droit de refuser l’accès à tout ou partie du Site à tout utilisateur qui ne les respecterait pas. Vous vous engagez par ailleurs à ne pas adresser ou transmettre des textes ou images contraires à la loi, ou susceptibles de choquer la sensibilité, notamment par un contenu haineux, pornographique, ou incitatif à des comportements du même ordre. SAS La Bulle de Vert se réserve le droit de prendre toute mesure, ou d’engager toute action qu’elle estime nécessaire au cas où son Site serait utilisé pour diffuser des éléments de cette nature. Nous vous informons que le Site est soumis au droit français, aux juridictions françaises et qu’il a pour langue officielle le français.</p>
            <h5>Propriété intellectuelle</h5>
            <p>Le Site et chacun des éléments qui le composent (les marques, graphismes, photographies…) relèvent de la législation française et internationale notamment celle relative au droit d’auteur, aux bases de données et à la propriété intellectuelle, en particulier, la marque « La Bulle de Vert » et son logo ainsi que toutes les réalisations publiées sur le Site. Toutes les autres marques citées sont la propriété de leurs titulaires respectifs. Toute reproduction, représentation, publication, transmission, ou plus généralement toute exploitation non autorisée du Site et/ou de ses éléments engage votre responsabilité et est susceptible d’entraîner des poursuites judiciaires, notamment pour contrefaçon.</p>
            <h5>Responsabilité</h5>
            <p>Le Site peut contenir des liens vers d’autres sites que nous n’exploitons pas. Nous ne pouvons en aucune manière être tenus responsables de la mise à disposition de ces liens permettant l’accès à ces sites et sources externes, et ne pouvons supporter aucune responsabilité quant au contenu, publicités, produits, services ou tout autre matériel disponible sur ou à partir de ces sites ou sources externes qui ne sont ni vérifiées ni approuvées par nos équipes. Nous nous engageons à assurer nos meilleurs efforts pour offrir des informations actualisées et exactes. Cependant, nous ne saurions être tenus pour responsables d’erreurs, d’omissions ou des résultats qui pourraient être obtenus par un mauvais usage de ces informations. Nous nous réservons le droit de les corriger, dès que ces erreurs sont portées à notre connaissance et, plus généralement, de modifier, à tout moment, sans préavis, tout ou partie du Site ainsi que ses conditions d’utilisation, sans que notre responsabilité puisse être engagée de ce fait. Le téléchargement de tous matériels lors de l’utilisation du service sera à vos risques et périls. Vous serez seul responsable pour tout dommage subi par votre ordinateur ou toutes pertes de données consécutives à ce téléchargement. Plus généralement, ADRIEN CHOUTEAU ne pourra en aucun cas être responsable en cas de dommages directs et/ou indirects résultant de l’utilisation de ce Site.</p>
        </div>
    );
};

export default Mentions;