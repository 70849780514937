import React from 'react';

const Testimonial = ({ name, paragraphs, picSrc }) => {
    setTimeout(() => {
        const ratio = .25;
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: ratio
        }

        const callback = function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.intersectionRatio > ratio) {
                    observer.unobserve(entry.target);
                    entry.target.classList.add('reveal-visible');
                } else {
                }
            })
        }
        const observer = new IntersectionObserver(callback, options);
        document.querySelectorAll('.reveal').forEach(function (r) {
            observer.observe(r);
        });

    }, 500);

    let texts = [];

    for (let p of paragraphs) {
        texts.push(
            <p className="reveal">
                {p}
            </p>
        )
    }
    return (
        <div id="testimonial">
            <div id="header">
                <div id="img">
                    <img src={picSrc} alt={name}></img>
                </div>
                <h1>Témoignage de {name}</h1>
                <div id="buttons">
                    <button id="back">Retour</button>
                </div>
                {texts}
            </div>
        </div>
    );
};

export default Testimonial;