import React from 'react';
import { NavLink } from 'react-router-dom';
import Activities from '../components/Activities';
import Place from '../components/Place';
import Sejours from '../components/Sejours';
import CloseNav from '../functions/CloseNav';

const Sejourner = () => {
    CloseNav();
    setTimeout(() => {
        const ratio = .25;
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: ratio
        }

        const callback = function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.intersectionRatio > ratio) {
                    observer.unobserve(entry.target);
                    entry.target.classList.add('reveal-visible');
                } else {
                }
            })
        }
        const observer = new IntersectionObserver(callback, options);
        document.querySelectorAll('.reveal-right').forEach(function (r) {
            observer.observe(r);
        });

        const observerbis = new IntersectionObserver(callback, options);
        document.querySelectorAll('.reveal').forEach(function (r) {
            observerbis.observe(r);
        });

    }, 500);

    var [rendering, setRendering] = React.useState(<Place />)

    React.useEffect(() => {
        const activLink = document.getElementById('activ-link');
        const lieuLink = document.getElementById('lieu-link');
        const sejourLink = document.getElementById('sejour-link');

        if (activLink) {
            activLink.addEventListener('click', () => {
                setRendering(<Activities />);
            })
        }

        if (lieuLink) {
            lieuLink.addEventListener('click', () => {
                setRendering(<Place />);
            })
        }

        if (sejourLink) {
            sejourLink.addEventListener('click', () => {
                setRendering(<Sejours />);
            })
        }
    })
    return (
        <div id="sejour">
            <h1>Séjourner</h1>
            <div id="anchors">
                <p id="lieu-link">Le Lieu</p>
                <p id="activ-link">Les Activités</p>
                <p id="sejour-link">Les Séjours</p>
            </div>
            {rendering}
        </div>
    );
};

export default Sejourner;