import React from 'react';
import CloseNav from '../functions/CloseNav';

const Book = () => {
    CloseNav();
    setTimeout(() => {
        const ratio = .25;
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: ratio
        }

        const callback = function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.intersectionRatio > ratio) {
                    observer.unobserve(entry.target);
                    entry.target.classList.add('reveal-visible');
                } else {
                }
            })
        }
        const observer = new IntersectionObserver(callback, options);
        document.querySelectorAll('.reveal').forEach(function (r) {
            observer.observe(r);
        });

    }, 500);
    var [render, setRender] = React.useState([
        <option value={'soc'}>-- Date d'arrivée --</option>
    ]);

    React.useEffect(() => {
        var availableDates = [];
        const form = document.getElementById('new_form');

        if (form) {
            form.style.display = "none";
            const inputs = document.querySelectorAll('.required-field');
            const axios = require('axios');
            const url = 'https://admin.labulledevert.fr/api/schedules';
            const blockUrl = 'https://admin.labulledevert.fr/api/block_dates.json';
            const submitBtn = document.getElementById('submit');
            submitBtn.style.display = "none";

            const getSchedules = async () => {

                try {
                    const spinner = document.getElementById('spinner');
                    const confirm = document.getElementById('confirm');
                    const spinner_min = document.getElementById('spinner-min');
                    const startInput = document.getElementById('schedule_start');
                    const durationInput = document.getElementById('schedule_duration');
                    const firstName = document.getElementById('schedule_firstName');
                    const lastName = document.getElementById('schedule_lastName');
                    const birthdate = document.getElementById('schedule_birthdate');
                    const phone = document.getElementById('schedule_phone');
                    const email = document.getElementById('schedule_email');
                    const address = document.getElementById('schedule_address');
                    const postalCode = document.getElementById('schedule_postalCode');
                    const city = document.getElementById('schedule_city');
                    const note = document.getElementById('schedule_note');
                    const response = await axios.get(url);
                    const blockResponse = await axios.get(blockUrl);

                    if (blockResponse && response) {
                        spinner.style.display = 'none';
                        availableDates.push(
                            <option value={'soc'}>-- Date d'arrivée --</option>
                        )

                        for (let date of blockResponse.data) {
                            let label = new Date(date.start).getDate() + '/' + parseInt(new Date(date.start).getMonth() + 1) + '/' + new Date(date.start).getFullYear()
                            availableDates.push(
                                <option key={date.id * Math.floor((Math.random() * 10) + 1)} value={date.start}>{label}</option>
                            )
                        }
                        setRender(availableDates)
                    }

                    submitBtn.style.display = "";
                    form.style.display = "";

                    const submitForm = () => {
                        submitBtn.style.display = "none";
                        spinner_min.style.display = "flex";

                        var params = new URLSearchParams();
                        params.append('first_name', firstName.value);
                        params.append('last_name', lastName.value);
                        params.append('email', email.value);
                        params.append('phone', phone.value);
                        params.append('birthdate', birthdate.value);
                        params.append('address', address.value + ' ' + postalCode.value + ' ' + city.value);
                        params.append('duration', durationInput.value);
                        params.append('note', note.value);
                        params.append('start', startInput.value);

                        let axios = require('axios');
                        const config = {
                            headers: {
                                // 'Content-Type': 'application/json',
                                // 'Access-Control-Allow-Origin': '*',
                            },
                        }

                        axios.post('https://admin.labulledevert.fr/sendmail', params, config).then(response => {
                            if (response.data && response.data === 1) {
                                submitBtn.style.display = "inline";
                                spinner_min.style.display = "none";
                                confirm.style.display = "block";
                                form.reset();
                                setTimeout(() => {
                                    confirm.style.display = "none";
                                }, 5000)
                            } else {
                                alert('Une erreur s\'est produite : veuillez réessayer ultérieurement.')
                            }
                        })
                    }

                    submitBtn.addEventListener('click', (e) => {
                        e.preventDefault();
                        var fieldsValue = [];

                        Array.from(inputs).forEach(input => { // VERIFY IF FIELDS NOT EMPTY 
                            if (!input.value) {
                                input.style.border = '1px solid red';
                                setTimeout(() => {
                                    input.style.border = '1px solid #ced4da';
                                }, 2000);
                                fieldsValue.push(false)
                            } else {
                                fieldsValue.push(true)
                            }
                        })

                        if (fieldsValue.includes(false)) {
                            return;
                        } else {
                            submitForm()
                        }
                    })
                } catch (err) {
                    console.error(err);
                }
            }
            getSchedules();
        }

    }, [])

    return (
        <div id="schedule">
            <div id="decoration" className="reveal">
                <h1>Réserver un séjour</h1>
                <img src="/images/undraw_nature_on_screen_xkli.svg" alt="schedule" />
                <div>
                    <p>Vous pouvez pré-réserver votre séjour à la Bulle de Vert via ce formulaire.</p>
                    <p>Nous vous recontacterons d'ici 48h pour confirmer votre réservation.</p>
                </div>

            </div>
            <div className="d-flex justify-content-center mt-5" id="spinner">
                <div></div>
            </div>

            <form id="new_form">
                <div className="form-content">
                    <div className="form-item">
                        <label htmlFor="firstname">Prénom *</label>
                        <input type="text" placeholder="Prénom" className="required-field" id="schedule_firstName" name="firstname" />
                    </div>
                    <div className="form-item">
                        <label htmlFor="lastname">Nom *</label>
                        <input type="text" placeholder="Nom" className="required-field" id="schedule_lastName" name="lastname" />
                    </div>
                    <div className="form-item">
                        <label htmlFor="email">Email *</label>
                        <input type="email" placeholder="Email" className="required-field" id="schedule_email" name="email" />
                    </div>
                    <div className="form-item">
                        <label htmlFor="phone">Phone *</label>
                        <input type="text" placeholder="Téléphone" className="required-field" id="schedule_phone" name="phone" />
                    </div>
                    <div className="form-item">
                        <label htmlFor="address">Adresse *</label>
                        <input type="text" placeholder="Adresse" className="required-field" id="schedule_address" name="address" />
                    </div>
                    <div className="form-item">
                        <label htmlFor="postal">Code postal *</label>
                        <input type="text" placeholder="Code Postal" className="required-field" id="schedule_postalCode" name="postal" />
                    </div>
                    <div className="form-item">
                        <label htmlFor="city">Ville *</label>
                        <input type="text" placeholder="Ville" className="required-field" id="schedule_city" name="city" />
                    </div>
                    <div className="form-item">
                        <label htmlFor="birthdate">Date de naissance *</label>
                        <input type="date" placeholder="Date de naissance" className="required-field" id="schedule_birthdate" name="birthdate" />
                    </div>
                    <div className="form-item">
                        <label htmlFor="start">Date d'arrivée *</label>
                        <select id="schedule_start" name="start">
                            {render}
                        </select>
                    </div>
                    <div className="form-item">
                        <label htmlFor="duration">Durée *</label>
                        <select className="required-field" id="schedule_duration" name="duration">
                            <option value="null">-- Durée --</option>
                            <option value="9">10 jours</option>
                            <option value="19">20 jours</option>
                        </select>
                    </div>
                    <div className="form-item">
                        <label htmlFor="note">Note</label>
                        <textarea rows="5" placeholder="Note" className="" id="schedule_note" name="note"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <span id="errorMessage"></span>
                </div>

                <div className="form-item">
                    <input type="submit" id="submit" value="Réserver" />
                    <div id="spinner-min"></div>
                </div>
                <div id="confirm">
                    <p>
                        Votre demande a bien été reçue, nous vous contacterons dans un délai de 48h
                        afin de confirmer votre venue.
                    </p>
                    <p>
                        Amicalement,
                    </p>
                    <p>Karine & Stéphane</p>
                </div>
            </form>
        </div>
    );
};

export default Book;