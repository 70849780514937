import React, { useState } from "react";

const Form = () => {

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const isEmail = () => {
        let mail = document.getElementById('not-mail');
        let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (email.match(regex)) {
            mail.style.display = 'none';
            return true;
        } else {
            mail.style.display = 'block';
            mail.style.animation = 'dongle 1s';
            setTimeout(() => {
                mail.style.animation = "none";
                mail.style.display = 'none';
            }, 1000);
            return false;
        }
    }

    const isPhone = () => {
        let phoneInput = document.getElementById('not-phone');
        let regex = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/;

        if (phone.match(regex)) {
            phoneInput.style.display = 'none';
            return true;
        } else {
            phoneInput.style.display = 'block';
            phoneInput.style.animation = 'dongle 1s';
            setTimeout(() => {
                phoneInput.style.animation = "none";
                phoneInput.style.display = 'none';
            }, 1000);
            return false;
        }
    }

    const failMessage = (text) => {
        let formMess = document.querySelector('.form-message-end');
        formMess.innerHTML = text;
        formMess.style.opacity = 1;
        formMess.style.background = "rgba(255,0,0,1)";
        document.getElementById('name').classList.add('error');
        document.getElementById('message').classList.add('error');
        document.getElementById('email').classList.add('error');
        document.getElementById('phone').classList.add('error');
    }

    const successMessage = () => {
        let formMess = document.querySelector('.form-message-end');
        formMess.innerHTML = "Message envoyé ! Nous vous recontacterons dans les plus brefs délais !";
        formMess.style.opacity = 1;
        formMess.style.background = "#2ecc71";

        document.getElementById('message').classList.remove('error');
        document.getElementById('email').classList.remove('error');
        document.getElementById('name').classList.remove('error');
        document.getElementById('phone').classList.remove('error');

        setTimeout(() => {
            formMess.style.opacity = '0';
        }, 3500)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (name && isEmail() && message && isPhone()) {
            sendFeedback();
            setName("");
            setPhone("");
            setEmail("");
            setMessage("");
        } else {
            failMessage("Merci de remplir correctement les champs requis*");
        }
    };

    const sendFeedback = () => {
        const submitBtn = document.getElementById('submit');
        submitBtn.style.display = "none";
        const spinner_min = document.getElementById('spinner-min');
        spinner_min.style.display = "flex";

        var params = new URLSearchParams();
        params.append('name', name);
        params.append('message', message);
        params.append('email', email);
        params.append('phone', phone);

        let axios = require('axios');
        const config = {
            headers: {
                // 'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': '*',
            },
        }

        axios.post('https://admin.labulledevert.fr/contactmail', params, config).then(response => {
            if (response.data && response.data === 1) {
                submitBtn.style.display = "inline";
                spinner_min.style.display = "none";
                const form = document.getElementById('myform');
                const confirm = document.getElementById('confirm');
                confirm.style.display = "block";
                form.reset();
                setTimeout(() => {
                    confirm.style.display = "none";
                }, 5000)
            } else {
                alert('Une erreur s\'est produite : veuillez réessayer ultérieurement.')
            }

        })
    }

    return (
        <form className="contact-form reveal-right" id="myform">
            <h1 id="contact-title">CONTACT</h1>
            <div className="form-content">

                <div className="form-item">
                    <input
                        type="text"
                        id="name"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Nom *"
                        value={name}
                        autoComplete="off"
                        className="field"
                    />
                </div>
                <div className="form-item">
                    <label id="not-phone">Numéro non valide</label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Numéro de téléphone *"
                        value={phone}
                        className="field"
                    />
                </div>
                <div className="email-content form-item">
                    <label id="not-mail">Email non valide</label>
                    <input
                        type="mail"
                        id="email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Adresse e-mail *"
                        value={email}
                        autoComplete="off"
                        className="field"
                    />
                </div>
                <div className="form-item">
                    <textarea
                        id="message"
                        name="message"
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Votre message *"
                        value={message}
                        className="field"
                    />
                </div>
            </div>
            <div className="form-item">
                <input
                    id="submit"
                    className="button"
                    type="button"
                    value="Envoyer"
                    onClick={handleSubmit}
                />
                <div id="spinner-min"></div>

            </div>

            <div id="confirm">
                <p>
                    Merci pour votre message ! Nous vous répondrons dans les plus brefs délais.
                </p>
                <p>
                    Amicalement,
                </p>
                <p>Karine & Stéphane</p>
            </div>        </form>
    );
};

export default Form;