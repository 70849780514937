import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <footer>
            <ul>
                <li><NavLink to="/mentions-legales">Mentions Légales</NavLink></li>
                <li>&copy; 2022 - La Bulle de Vert, tous droits réservés</li>
                <li><a href="https://sonofcoffee.fr" target="_blank" rel="noreferrer">Développé avec &hearts; par Son of Coffee Digital</a></li>
            </ul>
        </footer>
    );
};

export default Footer;