import React from 'react';
import { NavLink } from 'react-router-dom';
import { BsArrowRightCircle } from 'react-icons/bs';
import CloseNav from '../functions/CloseNav';

const Home = () => {
    CloseNav();
    setTimeout(() => {
        const ratio = .25;
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: ratio
        }

        const callback = function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.intersectionRatio > ratio) {
                    observer.unobserve(entry.target);
                    entry.target.classList.add('reveal-visible');
                } else {
                }
            })
        }
        const observer = new IntersectionObserver(callback, options);
        document.querySelectorAll('.reveal').forEach(function (r) {
            observer.observe(r);
        });

    }, 500);

    React.useEffect(() => {
        const mobile_menu = document.getElementById('mobile-menu');
        mobile_menu.style.display = "none";
    })

    return (
        <div id="home">
            <div id="home-pic">
                <div id="filter">
                    <h1>La Bulle de <span>Vert</span></h1>
                    <h2 id="subtitle">Un <span>lieu d’accueil</span> pour personnes en convalescence de burn-out</h2>
                    <button><NavLink to="/la-bulle">Découvrir  <BsArrowRightCircle /></NavLink></button>
                </div>
            </div>
            <div className="text">
                <p className="reveal">
                    En France, on estime que 490.000 personnes, soit près de 2 % de la population active, sont victimes chaque année d’un <span>burn-out</span>.
                </p>
                <img src="/images/undraw_thoughts_re_3ysu.svg" alt="thoughts" />
                <p className="reveal">
                    Cela peut paraître difficilement imaginable quand on se sent au creux de la vague, mais il y a une vie après le burn-out. Cet « après » peut même être plus beau, plus serein et en meilleure cohérence avec soi que « l’avant ». L’annonce de cette vie d’après peut paraître ambitieuse, mais elle est pourtant réelle. A condition de s’autoriser une vraie pause et de laisser du temps au temps pour pouvoir comprendre et se remettre.
                </p>
                <img src="/images/undraw_friendship_mni7.svg" alt="listening" />
                <p className="reveal">
                    La Bulle de Vert  a été créée à cette fin par Karine et Stéphane qui ont tous deux connu un burn-out, pour offrir aux personnes qui vivent un syndrome d’épuisement professionnel un lieu d’accueil, d’entraide et d’écoute bienveillante, un endroit où apprendre le temps de la convalescence à lâcher prise et à vivre au jour le jour, et ainsi prendre le chemin de la reconstruction.
                </p>

            </div>
        </div>
    );
};

export default Home;