import React from 'react';
import Article from '../components/Article';
import Biblio from '../components/Biblio';
import FurtherRow from '../components/FurtherRow';
import Video from '../components/Video';
import Articles from '../data/Articles';
import Biblios from '../data/Biblios';
import Videos from '../data/Videos';
import CloseNav from '../functions/CloseNav';

const Further = () => {
    CloseNav();
    var articles = [];
    for (let a of Articles) {
        let title = a.title;
        articles.push(<Article src={a.src} titre={title.slice(0, 50)} />)
    }
    var videos = [];
    for (let v of Videos) {
        videos.push(<Video src={v.src} titre={v.title} />)
    }

    var biblios = [];
    for (let b of Biblios) {
        biblios.push(<Biblio src={b.src} titre={b.title} />)
    }

    React.useEffect(() => {
        const links = document.querySelectorAll('.article, .video, .biblio');
        Array.from(links).forEach(a => {
            a.addEventListener('click', () => {
                window.open(a.dataset.link);
            })
        })

    })
    return (
        <div id="further">
            <h1>Aller plus loin</h1>
            <h2>Articles</h2>
            <FurtherRow elements={articles} />
            <h2>Bibliographie</h2>
            <FurtherRow elements={biblios} />
            <h2>Vidéos</h2>
            <FurtherRow elements={videos} />
        </div>
    );
};

export default Further;