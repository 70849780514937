import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { AiOutlineCloseCircle, AiOutlineMenu } from 'react-icons/ai';

const Nav = () => {
    const location = useLocation();
    var linksColor;
    var scrollLimit;
    switch (location.pathname) {
        case '/':
            linksColor = 'white';
            scrollLimit = 100;
            break;

        default:
            linksColor = 'black';
            scrollLimit = 50;
    }

    React.useEffect(() => {
        const close_btn = document.getElementById('close-btn');
        const open_btn = document.getElementById('open-btn');
        const mobile_menu = document.getElementById('mobile-menu');
        const menu = document.getElementById('menu');
        const allLis = Array.from(menu.getElementsByTagName('a'));
        var lis = [];
        allLis.forEach(li => {
            if (!li.classList.contains('subitem')) {
                lis.push(li);
            }
        })

        lis.forEach(li => {
            li.style.color = linksColor;
        })

        if (close_btn) {
            close_btn.addEventListener('click', () => {
                mobile_menu.style.display = "none";
            })
        }
        if (open_btn) {
            open_btn.addEventListener('click', () => {
                mobile_menu.style.display = "flex";
            })
        }
        document.onscroll = () => {
            var scrollPosition = window.scrollY;
            if (scrollPosition >= scrollLimit) {
                menu.style.background = 'rgb(42, 153, 88)';
                lis.forEach(li => {
                    li.style.color = 'white';
                })
            } else {
                menu.style.background = 'transparent';
                lis.forEach(li => {
                    li.style.color = linksColor;
                })
            }
        }

    }, [linksColor, scrollLimit])
    return (
        <>
            <nav id="menu">
                <ul >
                    <li><NavLink to="/">Accueil</NavLink></li>
                    <li>
                        <NavLink to="#">La Bulle de Vert</NavLink>
                        <div className="sub_items">
                            <NavLink to="/la-bulle" className="subitem">Présentation</NavLink>
                            <NavLink to="/la-bulle/sejourner" className="subitem">Séjourner</NavLink>
                            <NavLink to="/la-bulle/gallerie" className="subitem">Gallerie</NavLink>
                        </div>
                    </li>
                    <li>
                        <NavLink to="#">Le Burn-out</NavLink>
                        <div className="sub_items">
                            <NavLink to="/le-burnout" className="subitem">Comprendre</NavLink>
                            <NavLink to="/le-burnout/aller-plus-loin" className="subitem">Pour aller plus loin</NavLink>
                        </div>
                    </li>
                    <li><NavLink to="/reserver">Réserver</NavLink></li>
                    <li><NavLink to="/contact">Contact</NavLink></li>
                </ul>
            </nav>
            <nav id="mobile-menu">
                <AiOutlineCloseCircle id="close-btn" />
                <ul >
                    <li><NavLink to="/">Accueil</NavLink></li>
                    <li>
                        <NavLink to="#">La Bulle de Vert</NavLink>
                        <ul className="sublist">
                            <li><NavLink to="/la-bulle">Présentation</NavLink></li>
                            <li><NavLink to="/la-bulle/sejourner">Séjourner</NavLink></li>
                            <li><NavLink to="/la-bulle/gallerie">Gallerie</NavLink></li>
                        </ul>
                    </li>
                    <li>
                        <NavLink to="#">Le Burn-Out</NavLink>
                        <ul className="sublist">
                            <li><NavLink to="/le-burnout">Comprendre</NavLink></li>
                            <li><NavLink to="/le-burnout/aller-plus-loin">Aller plus loin</NavLink></li>
                        </ul>
                    </li>
                    <li><NavLink to="/reserver">Réserver</NavLink></li>
                    <li><NavLink to="/contact">Contact</NavLink></li>
                </ul>
            </nav>
            <AiOutlineMenu id="open-btn" />

        </>
    );
};

export default Nav;