import React from 'react';
import { NavLink } from 'react-router-dom';
import CloseNav from '../functions/CloseNav';

const Comprendre = () => {
    CloseNav();
    setTimeout(() => {
        const ratio = .25;
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: ratio
        }

        const callback = function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.intersectionRatio > ratio) {
                    observer.unobserve(entry.target);
                    entry.target.classList.add('reveal-visible');
                } else {
                }
            })
        }
        const observer = new IntersectionObserver(callback, options);
        document.querySelectorAll('.reveal').forEach(function (r) {
            observer.observe(r);
        });

    }, 500);
    return (
        <div id="understand">
            <h1>Comprendre le Burn-Out</h1>
            <img src="/images/undraw_medicine_b-1-ol.svg" alt="" />
            <p className="reveal">
                Le syndrome d&#39;épuisement professionnel ou burn-out est un état d&#39;épuisement physique,
                émotionnel et mental lié à une dégradation du rapport d&#39;une personne à son travail. Il est défini
                par la Haute Autorité de Santé (HAS) comme un état &quot;<em>d&#39;épuisement physique, émotionnel et mental qui
                    résulte d&#39;un investissement prolongé dans des situations de travail exigeantes sur le plan émotionnel</em> ».
            </p>
            <p className="reveal">
                Selon la définition qu’en donne de son côté l&#39;OMS dans la 11 ème révision de la classification
                internationale des maladies en 2019, le burn-out se caractérise par :
                <ul className="">
                    <li>un sentiment de fatigue intense et d&#39;épuisement</li>
                    <li>des sentiments négatifs ou cyniques liés au travail</li>
                    <li>une efficacité professionnelle réduite</li>
                </ul>
            </p>
            <p className="reveal">
                Il s’écoule souvent plusieurs années avant que ne survienne l&#39;effondrement. Le burn-out démarre
                et s’installe de façon pernicieuse et silencieuse, sans que l’intéressé ne soit conscient de son sur-
                engagement dans son activité professionnelle. Progressivement cette suractivité va commencer à
                déborder sur sa vie privée, familiale et sociale. Une anxiété profonde se manifeste et s’installe
                petit à petit, renforcée par la crainte pour la personne en burn-out de ne plus être aussi efficace et
                performante qu’avant. S’instaure alors un cycle infernal dans lequel, pour compenser ses
                angoisses et le sentiment d’incompétence, l’intéressé va s’investir davantage encore dans son
                travail.
            </p>
            <img src="/images/undraw_farm_girl_dnpe.svg" alt="" />
            <p className="reveal">
                Littéralement, faire un burn-out, c’est « brûler de l’intérieur, se consumer ». C’est une usure à petit feu,
                lente, progressive, qui trouve sa source dans le stress chronique subi au travail. L&#39;effondrement lui
                survient souvent d’un coup, de façon brutale : nombreux sont les témoignages de celles et ceux
                qui se sont littéralement trouvés dans l&#39;impossibilité totale, un matin, de se lever pour aller
                travailler.
            </p>
            <p className="reveal">
                Les symptômes du burn-out sont nombreux : irritabilité, troubles du sommeil, pertes de
                mémoire, difficultés de concentration, doutes sur ses compétences, baisse de l’estime de soi,
                découragement, démotivation …
            </p>
            <p className="reveal">
                La personne en burn-out présente également une fatigue intense et continue, physique et
                émotionnelle, que même les périodes de repos, week-ends et vacances, ne parviennent plus à
                juguler Et l&#39;idée même d&#39;aller travailler devient insupportable. La plupart du temps  des troubles
                psychosomatiques se manifestent également : migraines, maux d’estomac ou de ventre,
                problèmes de transit, douleurs diverses, faiblesse du système immunitaire etc …
            </p>
            <p className="reveal">
                La personne en burn-out devient fréquemment détachée, négative et indifférente. S&#39;observe alors
                une baisse de considération positive vis-à-vis des personnes côtoyées dans la vie professionnelle
                qui peut même déborder dans la vie sociale et personnelle. La personne se ferme alors
                totalement, elle se coupe de ses relations, de ses activités privées et de ses loisirs. On passe alors
                de l&#39;épuisement professionnel à la rupture avec la vie privée.
            </p>
            <p className="reveal">
                Tout le monde peut être concerné par l&#39;épuisement professionnel, quel que soient son
                domaine d’activité, son métier ou son milieu social. La plupart des facteurs de risque sont
                communs à de nombreuses situations de travail : la surcharge de travail, la perte de sens de la
                valeur travail, le manque de reconnaissance, l&#39;isolement, les réorganisations successives, la
                connexion continue à cause des nouvelles technologies...
            </p>
            <p className="reveal">
                Le plus souvent les victimes d’un burn-out sont des personnes très engagées dans leur travail, qui
                aiment leur métier, des personnes « pilier », perfectionnistes, en quête de reconnaissance,
                dévouées qui ne comptent ni leurs heures ni leur énergie, car se sont elles qui subissent le plus les
                tensions et le stress.
            </p>
            <p className="reveal">
                Selon l’Institut national de recherche et de sécurité, un tiers des travailleurs européens se
                plaignent de problèmes de santé liés à un travail stressant. En France, 10 000 affections
                psychiques sont reconnues en accident du travail chaque année; dont 600 au titre des maladies
                professionnelles. Ces dernières ont été multiplié par 7 en cinq ans. Et les durées d’arrêt de travail
                durent presque deux fois plus que la moyenne.
            </p>
            <p className="reveal">
                Ces chiffres, déjà importants, sont pourtant souvent décrits commet largement inférieurs à la
                réalité. En 2016, la mission parlementaire sur « l’épuisement professionnel » avait en effet relevé
                que l’extrapolation d’une veille mise en place dans les régions permet d’évaluer à  490 000 le
                nombre de cas d’épuisement professionnel par an (<a href="https://www.assemblee-nationale.fr/14/pdf/rap-
                info/i4487.pdf">Source</a>).
            </p>
            <p className="reveal">
                2% de la population active seraient ainsi concernés chaque année par le burn out.
            </p>
            <p>
                <NavLink to="/le-burnout/aller-plus-loin">Aller plus loin</NavLink>
            </p>




        </div>
    );
};

export default Comprendre;