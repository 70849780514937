import React from 'react';

const FurtherRow = ({ elements }) => {
    return (
        <div className="row">
            {elements}
        </div>
    );
};

export default FurtherRow;