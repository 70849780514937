import React from 'react';
import { MdOutlineArticle } from 'react-icons/md';

const Article = ({ src, titre }) => {
    return (
        <div className="article" data-link={src}>
            <MdOutlineArticle />
            <h3>{titre}</h3>
        </div>
    );
};

export default Article;