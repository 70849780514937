import React from 'react';
import { MdVideoSettings } from 'react-icons/md';

const Video = ({ src, titre }) => {
    return (
        <div className="video" data-link={src}>
            <MdVideoSettings />
            <h3>{titre}</h3>
        </div>
    );
};

export default Video;